import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, pontoParadaStore } from '@/store';
let PontoParadaList = class PontoParadaList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Referência',
                sortable: true,
                value: 'observacao',
                align: 'left',
            },
            {
                text: 'Bairro',
                sortable: true,
                value: 'bairro',
                align: 'left',
            },
            {
                text: 'Logradouro',
                sortable: true,
                value: 'logradouro',
                align: 'left',
            },
            {
                text: 'Número',
                sortable: true,
                value: 'numero',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Pontos de parada`]) {
            return userToolStore.userToolsIndexed[`Pontos de parada`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Pontos de parada`]) {
            return userToolStore.userToolsIndexed[`Pontos de parada`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Pontos de parada`]) {
            return userToolStore.userToolsIndexed[`Pontos de parada`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Pontos de parada`]) {
            return userToolStore.userToolsIndexed[`Pontos de parada`][0].allow_delete;
        }
    }
    get pontosParada() {
        return pontoParadaStore.pontosParada;
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão da pontoParada?',
            text: 'Após exclusão o ponto de parada não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await pontoParadaStore.deletePontoParada(id);
                // @ts-ignore
                await pontoParadaStore.getPontosParada();
                this.loading = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        await pontoParadaStore.getPontosParada();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-pontos-parada-edit', params: { id } });
    }
};
PontoParadaList = __decorate([
    Component
], PontoParadaList);
export default PontoParadaList;
